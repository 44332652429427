var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('div',{staticClass:"statistic-wrap list_grid grid-cols-1 2xl:grid-cols-2"},[(_vm.fundingSourceStatistic.length)?_c('div',{staticClass:"statistic_item"},[_c('div',{staticClass:"statistic_item__total"},[_c('div',{staticClass:"label"},[_vm._v("Всего: ")]),_c('div',{staticClass:"value"},[_c('span',{staticClass:"value__count"},[_vm._v(_vm._s(_vm.fundingSourceStatistic[0].total_sum))]),_c('span',{staticClass:"value__measure"},[_vm._v("млн.тг")])])]),_vm._l((_vm.fundingSourceStatistic),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"progress",on:{"mouseover":function($event){_vm.FCHovered = index},"mouseout":function($event){_vm.FCHovered = null}}},[_c('div',{staticClass:"label",class:[
                        {visible: _vm.FCHovered === index},
                        {left: index === 0},
                        {center: index !== 0 && index !== _vm.fundingSourceStatistic.length - 1},
                        {right: index === _vm.fundingSourceStatistic.length - 1}
                    ]},[_vm._v(" "+_vm._s(`${item.source.name} - ${item.source_sum} млн.тг`)+" ")]),_c('a-progress',{staticClass:"custom-progress",class:[{faded: _vm.FCHovered !== null && _vm.FCHovered !== index}, `trail-color-${index}`],attrs:{"percent":Number(item.source_percent),"show-info":false,"strokeWidth":21.45,"strokeColor":_vm.strokeColor(index)}}),_c('div',{staticClass:"progress-text",class:[
                        {faded: _vm.FCHovered !== null && _vm.FCHovered !== index},
                        {white: index === 0 || index === 3},
                        {black: index === 1 || index === 2}
                    ]},[_vm._v(" "+_vm._s(item.source_percent)+" % ")])],1)])})],2):_vm._e(),(_vm.categoryStatistic.length)?_c('div',{staticClass:"statistic_item"},[_c('div',{staticClass:"statistic_item__total"},[_c('div',{staticClass:"label"},[_vm._v("Всего: ")]),_c('div',{staticClass:"value"},[_c('span',{staticClass:"value__count"},[_vm._v(_vm._s(_vm.categoryStatistic[0].total_count))]),_c('span',{staticClass:"value__measure"},[_vm._v(_vm._s(_vm.projectWord(Number(_vm.categoryStatistic[0].total_count))))])])]),_vm._l((_vm.categoryStatistic),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"progress",on:{"mouseover":function($event){_vm.CHovered = index},"mouseout":function($event){_vm.CHovered = null}}},[_c('div',{staticClass:"label",class:[
                        {visible: _vm.CHovered === index},
                        {left: index === 0},
                        {center: index !== 0 && index !== _vm.categoryStatistic.length - 1},
                        {right: index === _vm.categoryStatistic.length - 1}
                    ]},[_vm._v(" "+_vm._s(`${item.category.name} - ${item.category_count} ${_vm.projectWord(Number(item.category_count))}`)+" ")]),_c('a-progress',{staticClass:"custom-progress",class:[{ faded: _vm.CHovered !== null && _vm.CHovered !== index }, `trail-color-${index}`],attrs:{"percent":Number(item.category_percent),"show-info":false,"strokeWidth":21.45,"strokeColor":_vm.strokeColor(index)}}),_c('div',{staticClass:"progress-text",class:[
                        {faded: _vm.CHovered !== null && _vm.CHovered !== index},
                        {white: index === 0 || index === 3},
                        {black: index === 1 || index === 2},
                    ]},[_vm._v(" "+_vm._s(item.category_percent)+" % ")])],1)])})],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }