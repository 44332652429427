<template>
    <div class="invest_card">
        <div class="card_header">
            <div class="card_header__left truncate">
                <div class="date cursor-pointer" @click="openProject()">Планируемый срок ввода: {{ $moment(item.dead_line).format('DD MMMM YYYY') }}г.</div>
                <h2 class="cursor-pointer truncate" @click="openProject()">{{ item.project_name }}</h2>
            </div>
            <div v-if="!isMobile" class="card_header__right">
                <a-button type="link" size="large" class="flex items-center" @click="openProject()">
                    Открыть
                    <i class="fi fi-rr-arrow-up-right ml-3 text-xs"></i>
                </a-button>
            </div>
        </div>
        <div class="card_body">
            <div class="left">
                <div class="card_list">
                    <div v-if="item.category" class="card_list__item">
                        <div class="name">Категория:</div>
                        <div class="value">{{ item.category.name }}</div>
                    </div>
                    <div v-if="item.subcategory" class="card_list__item">
                        <div class="name">Подкатегория:</div>
                        <div class="value">{{ item.subcategory.name }}</div>
                    </div>
                    <div class="card_list__item">
                        <div class="name">Кадастровый номер ЗУ:</div>
                        <div class="value">{{ item.cadaster }}</div>
                    </div>
                    <div v-if="item.company_director_name" class="card_list__item">
                        <div class="name">Директор:</div>
                        <div class="value">{{ item.company_director_name }}</div>
                    </div>
                    <div v-if="item.company_phone" class="card_list__item">
                        <div class="name">Телефон:</div>
                        <div class="value"><a :href="`tel:${item.company_phone}`">{{ item.company_phone }}</a></div>
                    </div>
                    <div v-if="item.location_full_name" class="card_list__item">
                        <div class="name">Местоположение:</div>
                        <div class="value">{{ item.location_full_name }}</div>
                    </div>
                </div>
            </div>
            <div v-if="!isMobile" class="right">
                <transition name="component-fade" mode="out-in">
                    <component :is="chartComponent" :item="item" />
                </transition>
            </div>
        </div>
        <div class="card_footer">
            <div class="flex items-center flex-wrap pr-3">
                <a-button v-if="item.stage && item.stage.name" type="success" ghost :size="isMobile ? 'default' : 'large'">
                    {{ item.stage.name }}
                </a-button>
                <a-button :type="item.has_documentation ?  'success' : 'primary'" ghost :size="isMobile ? 'default' : 'large'">
                    {{ item.has_documentation ?  'ПСД разработана' : 'ПСД не разработана' }}
                </a-button>
            </div>
            <div class="mt-3 md:mt-0">
                <a-checkbox 
                    :value="item.id" 
                    class="whitespace-nowrap select-none">
                    Убрать из выгрузки
                </a-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        chartComponent() {
            if(this.tab === 1)
                return () => import('./PriceChart.vue')
            if(this.tab === 2)
                return () => import('./PeopleChart.vue')

            return () => import('./PriceChart.vue')
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    data() {
        return {
            tab: 1
        }
    },
    methods: {
        openProject() {
            this.$router.push({ name: 'full_invest_project_info', params: { id: this.item.id } })
        }
    }
}
</script>

<style lang="scss" scoped>
.invest_card{
    padding: 15px;
    border: 1px solid var(--border2);
    border-radius: var(--borderRadius);
    display: flex;
    flex-direction: column;
    background: #ffffff;
    @media (min-width: 768px) {
        padding: 20px 20px 5px 20px;
    }
    .component-fade-enter-active, .component-fade-leave-active {
        transition: opacity .4s ease, transform .3s ease;
    }
    .component-fade-enter, .component-fade-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }
    .card_footer{
        border-top: 1px solid var(--border2);
        padding-top: 15px;
        margin-top: 15px;
        &::v-deep{
            .ant-btn{
                cursor: default;
                margin-bottom: 8px;
                &:not(:last-child){
                    margin-right: 8px;
                }
                @media (min-width: 768px) {
                    margin-bottom: 15px;
                    &:not(:last-child){
                        margin-right: 15px;
                    }
                }
            }
        }
        @media (min-width: 768px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 20px;
            margin-top: 20px;
        }
    }
    .tab_button{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .card_list{
        font-size: 14px;
        &__item{
            display: flex;
            &:not(:last-child){
                margin-bottom: 8px;
                @media (min-width: 1700px) {
                    margin-bottom: 14px;
                }
            }
            .name{
                min-width: 180px;
                max-width: 180px;
                color: #000000;
                opacity: 0.6;
                padding-right: 20px;
                word-break: break-word;
            }
            .value{
                color: #000000;
                word-break: break-word;
            }
        }
    }
    .card_body{
        @media (min-width: 768px) {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 20px;
            flex-grow: 1;
        }
        @media (min-width: 1700px) {
            gap: 40px;
        }
    }
    .card_header{
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        @media (min-width: 768px) {
            padding-bottom: 25px;
        }
        .date{
            font-size: 13px;
            margin-bottom: 8px;
            opacity: 0.6;
            color: #000000;
        }
        h2{
            font-size: 20px;
            margin: 0px;
        }
    }
}
</style>